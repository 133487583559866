import React, {createContext, useContext, useReducer} from 'react';

/* Action Types */
const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
const SET_ACTIVE_COMPETENCIES_TAB = 'SET_ACTIVE_COMPETENCIES_TAB';

const initialState = {
    activeTab: 'ux-design',
    activeCompetenciesTab: 'app-software-development'
};

const GlobalStateContext = createContext(initialState);

const globalStateReducer = (state, action) => {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload,
            };
        case SET_ACTIVE_COMPETENCIES_TAB:
            return {
                ...state,
                activeCompetenciesTab: action.payload,
            };
        default:
            return state;
    }
};

export const GlobalStateProvider = ({children}) => {
    const [state, dispatch] = useReducer(globalStateReducer, initialState);

    return (
        <GlobalStateContext.Provider value={[state, dispatch]}>
            {children}
        </GlobalStateContext.Provider>
    );
};

const useGlobalState = () => {
    const [state, dispatch] = useContext(GlobalStateContext);

    const setActiveTab = (activeTab) => {
        dispatch({
            type: SET_ACTIVE_TAB,
            payload: activeTab,
        });
    };
    const setActiveCompetenciesTab = (activeCompetenciesTab) => {
        dispatch({
            type: SET_ACTIVE_COMPETENCIES_TAB,
            payload: activeCompetenciesTab,
        });
    };

    return {
        setActiveTab,
        setActiveCompetenciesTab: setActiveCompetenciesTab,
        activeTab: typeof state !== 'undefined' ? state.activeTab : initialState.activeTab,
        activeCompetenciesTab: typeof state !== 'undefined' ? state.activeCompetenciesTab : initialState.activeCompetenciesTab
    };
};

export default useGlobalState;
