/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require("react");
const {GlobalStateProvider} = require("./src/hooks/useGlobalState");

exports.wrapRootElement = ({element}) => {
    return (
        <GlobalStateProvider>{element}</GlobalStateProvider>
    );
};
