// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-blog-template-blog-template-js": () => import("./../../../src/templates/blog-template/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-blog-template-js" */),
  "component---src-templates-jobs-category-page-template-js": () => import("./../../../src/templates/jobsCategoryPageTemplate.js" /* webpackChunkName: "component---src-templates-jobs-category-page-template-js" */),
  "component---src-templates-magazine-category-page-template-js": () => import("./../../../src/templates/magazineCategoryPageTemplate.js" /* webpackChunkName: "component---src-templates-magazine-category-page-template-js" */),
  "component---src-templates-story-category-page-template-js": () => import("./../../../src/templates/storyCategoryPageTemplate.js" /* webpackChunkName: "component---src-templates-story-category-page-template-js" */),
  "component---src-templates-story-template-story-template-js": () => import("./../../../src/templates/story-template/storyTemplate.js" /* webpackChunkName: "component---src-templates-story-template-story-template-js" */),
  "component---src-templates-vacancy-template-vacancy-template-js": () => import("./../../../src/templates/vacancy-template/vacancyTemplate.js" /* webpackChunkName: "component---src-templates-vacancy-template-vacancy-template-js" */)
}

